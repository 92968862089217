import { Ref } from 'vue';

class Debug {
  protected debugButtonDispFlg: Ref<boolean>;

  constructor (debugButtonDispFlg: Ref<boolean>) {
    this.debugButtonDispFlg = debugButtonDispFlg;
  }

  /**
   * デバッグボタンの表示フラグをセット
   * @param {boolean} debugButtonDispFlg
   */
  public setDebugButtonDispFlg (debugButtonDispFlg: boolean) {
    this.debugButtonDispFlg.value = debugButtonDispFlg;
  }

  /**
   * デバッグボタンを表示するか
   */
  get isShowDebugButton () {
    return this.debugButtonDispFlg.value;
  }
}

/**
 * デバッグ関連情報を保存・取得する
 */
export const useDebug = () => {
  const debugButtonDispFlg = useState<boolean>('debugButtonDispFlg', () => true);
  return new Debug(debugButtonDispFlg);
};
